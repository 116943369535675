import internalDocumentsListStore from '@/modules/internal-documents/store/internal-documents-list-store';
import internalDocumentFormStore from '@/modules/internal-documents/store/internal-document-form-store';
import internalDocumentsActivitiesStore from './store/internal-documents-activities-store';

export default {
    namespaced: true,
    modules: {
        list: internalDocumentsListStore,
        form: internalDocumentFormStore,
        activity: internalDocumentsActivitiesStore
    }
};
