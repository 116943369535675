import ApiService from '@/shared/services/api.service';

export class FormsService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/forms', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static find(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/forms/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/forms', { ...data } )
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/forms/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static async remove(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/forms/${id}`)
                .then(() => resolve())
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static async listAutocomplete() {
        const response = await ApiService.get('/forms?limit=500&fields=id,name');
        return response?.data?.data || [];
    }
}
