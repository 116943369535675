export const ENROLLMENT_ERRORS = [
    'ENROLLMENT_INVALID_CNP_FIELD',
    'ENROLLMENT_INVALID_ID_CARD_EXPIRATION_DATE_FIELD',
    'ENROLLMENT_INVALID_LEGAL_AGE_NOT_MET',
    'INVALID_CERTSIGN_ENROLLMENT',
    'INVALID_PROFILE_FOR_SIGNING'
];

export const RE_ENROLL_ERRORS = [
    'ENROLLMENT_NEED_TO_REENROLL'
];
